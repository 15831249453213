export const SHED_ALL_DATA_QUERY = `
{
  "articles": *[_type == "article"] | order(_updatedAt desc) {
    title,
    "slug": slug.current,
    author -> {
      name,
    },
    publishedAt,
      "updatedAt": _updatedAt,
    category {
      articleCategory -> {
        name,
        "slug": slug.current,
      },
      articleSubCategory -> {
        name,
        "slug": slug.current,
      },
    },
    shortDescription,
    tags[] -> {
      name,
      "slug": slug.current,
    },
    thumbnailImage,
    "thumbnailImagePreview": thumbnailImage.asset-> metadata.lqip,
  },
	"tags": *[_type == "tag"] | order(name asc) {
    name,
    "slug": slug.current
  },
	"categories": *[_type == "articleCategory"] | order(name asc) {
    name,
    "slug": slug.current
  },
}
`;

/**
 * --Partial query--
 *
 * Return an article's relatedProducts if there are any defined
 * or the relatedProducts defined on the article's category if not.
 */
const RELATED_PRODUCTS_WITH_DEFAULTS = `
count(relatedProducts) > 0 => {
  relatedProducts[] -> {
    name,
    primaryImage,
    "slug": slug.current,
    "sku": sku.current,
  }
},

!defined(relatedProducts) || count(relatedProducts) == 0 => {
  "relatedProducts": *[_type == "articleCategory"
                       && _id == ^.category.articleCategory._ref
                      ].relatedProducts[]-> {
    name,
    primaryImage,
    "slug": slug.current,
    "sku": sku.current,
  }
}`;

/**
 * --Partial query--
 *
 * Return an article's recommendedReading if there are any defined
 * or the recommendedArticles defined on the article's category if not.
 */
const RECOMMENDED_READING_WITH_DEFAULTS = `
count(recommendedReading) > 0 => {
  recommendedReading[] -> {
    title,
    "slug": slug.current,
    author -> {
      name,
    },
    publishedAt,
      "updatedAt": _updatedAt,
    thumbnailImage,
    shortDescription,
    category {
      articleCategory -> {
        name,
        "slug": slug.current,
      },
      articleSubCategory -> {
        name,
        "slug": slug.current,
      },
    },
  }
},

!defined(recommendedReading) || count(recommendedReading) == 0 => {
  "recommendedReading": *[_type == "articleCategory"
                       && _id == ^.category.articleCategory._ref
                      ].recommendedArticles[]-> {
    title,
    "slug": slug.current,
    author -> {
      name,
    },
    publishedAt,
      "updatedAt": _updatedAt,
    thumbnailImage,
    "thumbnailImagePreview": thumbnailImage.asset-> metadata.lqip,
    shortDescription,
    category {
      articleCategory -> {
        name,
        "slug": slug.current,
      },
      articleSubCategory -> {
        name,
        "slug": slug.current,
      },
    },
  }
}`;

/**
 * Variables: $slug
 */
export const SHED_ARTICLE_QUERY = `
*[_type == "article" && slug.current == $slug] | order(_updatedAt desc) {
  articleTemplate,
  "uuid": _id,
  title,
  "slug": slug.current,
  author -> {
    name,
    bio,
    photo,
    "slug": slug.current,
  },
  publishedAt,
  "updatedAt": _updatedAt,
  heroImage,
  heroVideo,
  thumbnailImage,
  "thumbnailImagePreview": thumbnailImage.asset-> metadata.lqip,
  shortDescription,
  bodyContent[] {
    ...,
    _type == "productWidget" => {
      product -> {
        name,
        primaryImage,
        "slug": slug.current,
      },
    },
    _type == "orderedListItem" => {
      orderNumber,
      headerTitle,
      body[] {
        ...,
        markDefs[] {
          ...,
          _type == "link" => {
            articleLink -> {
              "slug": slug.current,
              category {
                articleCategory -> {
                  "slug": slug.current,
                },
                articleSubCategory -> {
                  name,
                  "slug": slug.current,
                },
              },
            },
            fileLink {
              asset -> {
                assetId,
                extension,
                originalFilename,
              }
            },
            productLink -> {
              "slug": slug.current,
            }
          },
        }
      },
    },
    _type == "sundayTip" => {
      icon,
      text[] {
        ...,
        markDefs[] {
          ...,
          _type == "link" => {
            articleLink -> {
              "slug": slug.current,
              category {
                articleCategory -> {
                  "slug": slug.current,
                },
                articleSubCategory -> {
                  name,
                  "slug": slug.current,
                },
              },
            },
            fileLink {
              asset -> {
                assetId,
                extension,
                originalFilename,
              }
            },
            productLink -> {
              "slug": slug.current,
            }
          },
        }
      },
    },
    markDefs[] {
      ...,
      _type == "link" => {
        articleLink -> {
          "slug": slug.current,
          category {
            articleCategory -> {
              "slug": slug.current,
            },
            articleSubCategory -> {
              name,
              "slug": slug.current,
            },
          },
        },
        fileLink {
          asset -> {
            assetId,
            extension,
            originalFilename,
          }
        },
        productLink -> {
          "slug": slug.current,
        }
      },
    }
  },
  category {
    articleCategory -> {
      name,
      "slug": slug.current,
    },
    articleSubCategory -> {
      name,
      "slug": slug.current,
    },
  },
  tags[] -> {
    name,
    "slug": slug.current,
  },
  ${RELATED_PRODUCTS_WITH_DEFAULTS},
  ${RECOMMENDED_READING_WITH_DEFAULTS},
  howTo,
  seo
}[0]`;

/**
 * --Partial query--
 *
 * Returns article data pertinent to home page ArticleBlock components
 */
const ARTICLE_METADATA = `
  title,
  shortDescription,
  "slug": slug.current,
  author -> {
    name,
    photo,
    "slug": slug.current,
  },
  publishedAt,
    "updatedAt": _updatedAt,
  heroImage,
  thumbnailImage,
  "thumbnailImagePreview": thumbnailImage.asset-> metadata.lqip,
  category {
    articleCategory -> {
      name,
      "slug": slug.current,
    },
    articleSubCategory -> {
      name,
      "slug": slug.current,
    },
  },
`;

export const SHED_HOME_PAGE_QUERY = `
*[_type == "homePage"] | order(_updatedAt desc) {
  heroArticle -> {
    ${ARTICLE_METADATA}
  },

  subNav {
    categories[] {
      category -> {
        name,
        "slug": slug.current,
        thumbnailImage,
        highlightedArticles {
          articles[] -> {
            ${ARTICLE_METADATA}
          }
        }
      }
    }
  },

  highlight1 {
    header,
    articles[] -> {
      ${ARTICLE_METADATA}
    }
  },

  highlight2 {
    header,
    articles[] -> {
      ${ARTICLE_METADATA}
    }
  },

  seasonal {
    header,
    articles[] -> {
      ${ARTICLE_METADATA}
    }
  },

  featuredArticle {
    article -> {
      ${ARTICLE_METADATA}
    }
  },

  seo,

  "recentArticles": *[_type == "article"] | order(_updatedAt desc) {
    ${ARTICLE_METADATA}
  }[0..8],
}[0]
`;

/**
 * Variables: $category
 */
export const SHED_CATEGORY_PAGE_QUERY = `
{
  "category": *[_type == "articleCategory" && slug.current == $slug] {
    name,
    "slug": slug.current,
    type,
    subCategory[] -> {
      name,
      "slug": slug.current,
    },
    thumbnailImage,
    shortDescription,
    highlightedArticles {
      header,
      articles[] -> {
        ${ARTICLE_METADATA}
      }
    },
    featuredArticle -> {
      ${ARTICLE_METADATA}
    },
    ${RELATED_PRODUCTS_WITH_DEFAULTS},
    seo,
  }[0],
  "articles": *[_type == "article" && (
    category.articleCategory->slug.current == $slug ||
    category.articleSubCategory->slug.current == $slug
  )] {
    ${ARTICLE_METADATA}
  }
}
`;
